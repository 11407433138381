<script setup lang="ts">
import { getImage, getLink } from '@mop/cms/utils/utils';
import type { CmsLink } from '@mop/cms/types/';
import type { CmsStoryModel } from '@/types/cms';

defineOptions({
  name: 'MopBlogNewsItem',
  layout: 'LayoutDefault',
});

const props = defineProps({
  story: {
    type: Object as PropType<CmsStoryModel>,
    required: true,
  },
});

const { $mopI18n } = useNuxtApp();
const cmsContentState = computed(() => {
  const story: CmsStoryModel = props.story;
  const publishedDate = story.getAttribute('publishedDate');
  const title = story.getAttribute('previewTitle') || story.getAttribute('title');
  const subTitle = story.getAttribute('previewSubTitle') || story.getAttribute('subTitle');
  const image = getImage(story.getAttribute('previewImage'));
  const link: CmsLink = getLink({
    story: story.getDebugData(),
  });
  const uiCmsData = {
    text: $mopI18n.t('locale.read_more'),
  };

  return {
    publishedDate,
    title,
    subTitle,
    image,
    link,
    uiCmsData,
  };
});
</script>

<template>
  <NuxtLink :to="cmsContentState.link.to" class="blog-news-item" no-prefetch>
    <div class="blog-news-item__img-wrapper">
      <UiImage
        :image="cmsContentState.image"
        type="list-preview"
        cover
        width="373"
        height="373"
        class="blog-news-item__img"
      />
    </div>
    <div class="blog-news-item__content">
      <div class="blog-news-item__published-date">
        {{ $mopI18n.formatDate(cmsContentState.publishedDate) }}
      </div>
      <h3 class="blog-news-item__headline">
        {{ cmsContentState.title }}
      </h3>
      <div class="blog-news-item__subline">
        {{ cmsContentState.subTitle }}
      </div>
      <UiCmsCta :data="cmsContentState.uiCmsData" no-padding class="blog-news-item__cta" />
    </div>
  </NuxtLink>
</template>

<style lang="scss" scoped>
$image-width: 373px;
$image-height: 373px;

.blog-news-item {
  display: flex;
  margin-bottom: $space10;
  border-bottom: 1px solid $black;
  padding-bottom: $space10;

  @include apply-upto(small) {
    flex-direction: column;
    padding-bottom: 0;
  }
}

.blog-news-item__img-wrapper {
  flex-basis: $image-width;
  margin-right: $space20;

  @include apply-upto(small) {
    flex-basis: auto;
    margin-right: 0;
  }
}

.blog-news-item__img {
  width: $image-width;
  height: $image-height;

  @include apply-upto(small) {
    height: $image-height;
    width: 100%;
  }
}

.blog-news-item__content {
  overflow: hidden;
}

.blog-news-item__published-date {
  @include text-style(strong-small);

  @include apply-upto(small) {
    margin-top: $space10;
  }
}

.blog-news-item__headline {
  @include text-style(headline-condensed);

  margin: $space5 0 $space10 0;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.blog-news-item__subline {
  @include text-style(paragraph);

  line-height: 14px;
  max-height: 85px;
  overflow: hidden;

  @include apply-upto(small) {
    max-height: none;
    overflow: visible;
  }
}

.blog-news-item__cta {
  padding-top: $space5;
  text-transform: uppercase;
}
</style>
